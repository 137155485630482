@font-face {
  font-display: swap;
  font-family: 'Barlow Semi Condensed';
  font-style: 'normal';
  font-weight: 100;
  src: local(''), url('../__assets/fonts/BarlowSemiCondensed-Thin.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Barlow Semi Condensed';
  font-style: 'normal';
  font-weight: 400;
  src: local(''), url('../__assets/fonts/BarlowSemiCondensed-Regular.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Barlow Semi Condensed';
  font-style: 'normal';
  font-weight: 600;
  src: local(''), url('../__assets/fonts/BarlowSemiCondensed-Medium.woff2') format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'Barlow Semi Condensed';
  font-style: 'normal';
  font-weight: 700;
  src: local(''), url('../__assets/fonts/BarlowSemiCondensed-SemiBold.woff2') format('woff2');
}
